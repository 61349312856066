html {
  height: 100%;
}

body {
  height: 100%;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

#root {
  height: 100%;
}

.pac-container {
  z-index: 10000;
}

.page-account .appShellHeader {
  display: none;
}
